<template>
<div class="stock-view" >
    <div class="stock-top-view ">
        <div class="stock-top-bg">
            <img src="@/assets/img/stockbg.svg" alt="">
        </div>
    </div>
    <div class="flex justify-center">
        <div class="stock mw-1920  ">
            <div class="stock-one-items">
                <div class="stock-item-left">
                    <div class="stock-left-title ">
                        <h1 v-if="!loading">{{Stock[`description_${$i18n.locale}`]}}</h1> 
                        <p v-if="!loading && Stock.start_date"> {{Stock.start_date.split('-')[2] }}.{{Stock.start_date.split('-')[1]}} - {{Stock.end_date.split('-')[2]}}.{{Stock.end_date.split('-')[1]}}.{{Stock.end_date.split('-')[0]}} </p>
                          <el-skeleton style="width:100%;" v-if="loading" animated>
                            <template slot="template"> 
                            <div style="padding: 14px 0px;">
                                <el-skeleton-item variant="h1" style="width: 50%" />
                                <el-skeleton-item variant="p" style="width: 100%; margin-top:20px" />  
                            </div>
                            </template>
                        </el-skeleton>


                        <div   class="stock-left-title-bg" :style="{'background':`url(${'https://avior.prestigeautofl.com'+Stock.image})`}">
                            <div></div>
                        </div>
                    </div>
                    
                    <div class="stock-image-content" >
                        <div class="stock-img" >
                            <img v-if="!loading" :src="`${Stock.image?'https://avior.prestigeautofl.com'+Stock.image:require('@/assets/img/bg1.png')}`" alt="">
                            <el-skeleton style="width: 100%;" v-if="loading" >
                                <template slot="template">
                                        <el-skeleton-item variant="h1" style="width: 100%; height:300px; " />
                                </template>
                            </el-skeleton>
                        </div>
                    </div>
                     

                     <div class="stock-date phone">
                        <p v-if="!loading && Stock.start_date ">{{Stock.start_date.split('-')[2] }}.{{Stock.start_date.split('-')[1]}} - {{Stock.end_date.split('-')[2]}}.{{Stock.end_date.split('-')[1]}}.{{Stock.end_date.split('-')[0]}} </p>
                          <el-skeleton style="width:100%;" v-if="loading" animated>
                            <template slot="template"> 
                            <div style="padding: 14px 0px;">
                                <el-skeleton-item variant="h1" style="width: 50%" />  
                            </div>
                            </template>
                        </el-skeleton>
                    </div>
                  
                    <div class="stock-text" v-if="!loading" v-html="Stock[`text_${$i18n.locale}`]">
                    </div>
                    <el-skeleton style="width: 100%;" v-if="loading"  animated>
                                <template slot="template">
                                    <div class="stock-text">
                                        <el-skeleton-item variant="p" style="width: 70%; margin-top:10px" />
                                        <el-skeleton-item variant="p" style="width: 60%; margin-top:10px" />
                                        <el-skeleton-item variant="p" style="width: 100%; margin-top:10px" />
                                        <el-skeleton-item variant="p" style="width: 80%; margin-top:10px" />
                                        <el-skeleton-item variant="p" style="width: 100%; margin-top:10px" />
                                        <el-skeleton-item variant="p" style="width: 100%; margin-top:10px" />
                                        <el-skeleton-item variant="p" style="width: 100%; margin-top:10px" />
                                        </div>
                                </template>
                    </el-skeleton>
                   
                </div>
                <div class="stock-item-right">
                    <div class="stock-item-right-title">
                        <h1> {{$t('stock_list')}} </h1>
                    </div>
                    <div class="w-full desktop-slick" v-if="!loading">
                        <div class="stock-card-content" v-for="item in StocksList" :key="item.id">
                            <div class="stock-card">
                                <div class="stock-card-text-content">
                                    <h1> {{item[`title_${$i18n.locale}`]}} </h1>
                                    <p> {{item[`title_${$i18n.locale}`]}} </p>
                                </div>
                                <div class="stock-card-bottom-item">
                                    <button @click="$router.push(`/stocks/${item.id}`)"> {{$t('stock_show')}} </button>
                                    <p v-if="item.start_date" >{{item.start_date.split('-')[2] }}.{{item.start_date.split('-')[1]}} - {{item.end_date.split('-')[2]}}.{{item.end_date.split('-')[1]}}.{{item.end_date.split('-')[0]}}</p>
                                </div>
                                <div class="stock-card-bg" :style="{'background':`url(${item.image?'https://avior.prestigeautofl.com/'+item.image:require('@/assets/img/bg1.png')})`}"></div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full desktop-slick" v-if="loading">
                        <div class="stock-card-content" v-for="i in 4" :key="i">
                            <div class="stock-card">
                                <div class="stock-card-text-content">
                                    <el-skeleton style="width:100%;"   animated>
                                    <template slot="template"> 
                                    <div style="padding: 14px 0px;">
                                        <el-skeleton-item variant="h1" style="width: 80%" />  
                                        <el-skeleton-item variant="p" style="width: 100%" />  
                                        <el-skeleton-item variant="p" style="width: 60%" />  
                                    </div>
                                    </template>
                                </el-skeleton>
                                </div>
                                <div class="stock-card-bottom-item">
                                    <button  >{{$t('stock_show')}}</button>
                                    <el-skeleton style="width:100%;"   animated>
                                    <template slot="template"> 
                                        <div style="padding: 14px 0px 0px 20px;">
                                            <el-skeleton-item variant="h1" style="width: 100%" />   
                                        </div>
                                    </template>
                                </el-skeleton>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="w-full phone-slick" style="overflow: hidden;" v-if="StocksList.length>0">
                        <VueSlickCarousel v-bind="settings" v-if="!loading">
                            <div class="stock-card-content" v-for="item in StocksList" :key="item.id">
                                <div class="stock-card">
                                    <div class="stock-card-text-content">
                                        <h1> {{item[`title_${$i18n.locale}`]}} </h1>
                                        <p> {{item[`title_${$i18n.locale}`]}} </p>
                                    </div>
                                    <div class="stock-card-bottom-item">
                                        <button @click="$router.push(`/stocks/${item.id}`)">{{$t('stock_show')}}</button>
                                        <p v-if="item.start_date">{{item.start_date.split('-')[2] }}.{{item.start_date.split('-')[1]}} - {{item.end_date.split('-')[2]}}.{{item.end_date.split('-')[1]}}.{{item.end_date.split('-')[0]}}</p>
                                    </div>
                                    <div class="stock-card-bg" :style="{'background':`url(${item.image?'https://avior.prestigeautofl.com/'+item.image:require('@/assets/img/bg1.png')})`}" ></div>
                                </div>
                            </div>
                        </VueSlickCarousel>
                        <VueSlickCarousel v-bind="settings" v-if="loading">
                           <div class="stock-card-content" v-for="i in 4" :key="i">
                            <div class="stock-card">
                                <div class="stock-card-text-content">
                                    <el-skeleton style="width:100%;"   animated>
                                    <template slot="template"> 
                                    <div style="padding: 14px 0px;">
                                        <el-skeleton-item variant="h1" style="width: 80%" />  
                                        <el-skeleton-item variant="p" style="width: 100%" />  
                                        <el-skeleton-item variant="p" style="width: 60%" />  
                                    </div>
                                    </template>
                                </el-skeleton>
                                </div>
                                <div class="stock-card-bottom-item">
                                    <button  >{{$t('stock_show')}}</button>
                                    <el-skeleton style="width:100%;"   animated>
                                    <template slot="template"> 
                                        <div style="padding: 14px 0px 0px 20px;">
                                            <el-skeleton-item variant="h1" style="width: 100%" />   
                                        </div>
                                    </template>
                                </el-skeleton>
                                </div>
                            </div>
                        </div>
                        </VueSlickCarousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
    name: 'stock',
    components: {
        VueSlickCarousel,
    },
    data() {
        return {
            Stock: {},
            StocksList: [],
            loading: false,
            settings: {
                "dots": true,
                "infinite": true,
                "centerMode": true,
                "centerPadding": "20px",
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "variableWidth": true,
                "responsive": [{
                    "breakpoint": 600,
                    "settings": {
                        "accessibility": false,
                        "dots": false,
                        "infinite": true,
                        "slidesToShow": 1,
                        "slidesToScroll": 1,
                        "adaptiveHeight": true,
                    }
                }],
            }
        }
    },
    watch: {
        '$route.params.id'() {
            this.Get()
            this.GetStocks()
        }
    },
    methods: {
        Get() {
            this.loading = true
            this.axios.get(`/stock/${this.$route.params.id}`).then(res => {
                    this.Stock = res.data.data
                })
                .finally(() => {
                    this.loading = false
                })
        },
        GetStocks() {
            try {
                this.loading = true
                this.axios.get('/stock?per_page=5').then(res => {
                        this.StocksList = []
                        for(var i in res.data.data.data){
                            if(res.data.data.data[i].id!=this.$route.params.id){
                                this.StocksList.push(res.data.data.data[i]) 
                            }
                        }
                         

                    })
                    .finally(() => {
                        this.loading = false
                    })
            } catch { 
                return 0
            }
        }

    },
    mounted() {
        this.Get()
        this.GetStocks()
    }

}
</script>

<style lang="scss" scoped>
.stock-top-view {
    width: 100%;
    text-align: center;
    background: var(--bg-top); 
    position: relative;
    padding: 210px 0px;
     
}
.phone{
    display: none;
}

.stock-mover {
    transform: translateY(-100px);
}

.stock-top-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    img {
        width: 20%;

    }
}

.stock {
    width: 100%;
    padding: 0px 90px;

}

.stock-one-items {
    display: flex;
    flex-wrap: wrap;
    transform: translateY(-140px);

}

.stock-item-left {
    flex: 1;
    padding: 0px 30px;
    padding-right: 100px;

    .stock-left-title {
        position: relative;
        min-height: 60px;

        h1 {
            font-family: Stem-Medium;
            font-size: 40px;
            line-height: 42px;
            font-feature-settings: 'tnum'on, 'lnum'on;
            color: var(--other-black);
            position: relative;
            z-index: 2;
        }

        p {
            font-family: Stem-Medium;
            font-size: 1rem;
            line-height: 1.125rem;
            color: var(--white-black);
            margin-top: 20px;
            position: relative;
            z-index: 2; 
        }

        .stock-left-title-bg {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            background-size: cover !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
            display: none;

            div {
                width: 100%;
                height: 100%;
                background: black;
                opacity: 0.5;
            }
        }

    }

    .stock-image-content {
        margin-top: 60px;
        border-radius: 10px;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    .stock-text {
        padding-top: 50px;
        font-family: Stem-Regular;
        font-size: 1rem;
        line-height: 24px;
        color: var(--other-black);
    }
    .stock-date{
        padding: 20px 0px 0px 0px;
        display: none; 
        p{ 
            font-family: Stem-Regular;
            font-size: 1rem;
            line-height: 1.125rem;
            font-weight: 600;
            color: var(--other-black);
            opacity: 0.8;
        }
    }

}

.stock-item-right {
    width: 460px;
    padding: 0px 30px;

    .stock-item-right-title {
        h1 {
            font-family: Stem-Medium;
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 20px;
            color: var(--other-black);
        }
    }
    .stock-card-content {
        padding: 20px 0px;
        max-width: inherit;
    }
   

}

.stock-card {
    padding: 20px;
    background: var(--white-bg);
    box-shadow: 0px 6.53254px 17.4201px -8.71006px rgba(0, 72, 153, 0.12), 0px 9.79882px 30.4852px rgba(0, 72, 153, 0.05), 0px 13.0651px 52.2604px 17.4201px rgba(0, 72, 153, 0.03);
    border-radius: 5px;
    max-width: 370px;
    min-width: 320px;
    min-height: 180px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    .stock-card-bg{
        position: absolute;
        top: -100%;
        left: 0;
        width: 100%;
        height: 100%; 
        background-size: cover !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        z-index: 0;
        transition: all 0.3s ease-in;
    }
    h1 {
        font-family: Stem-Medium;
        font-size: 1.125rem;
        line-height: 1.25rem;
        color: var(--other-black); 
        transition: all 0.3s ease-in;
    }

    p {
        font-family: Stem-Regular;
        font-size: 0.875rem;
        line-height: 1rem;
        color: var(--white-black);
        margin-top: 10px; 
        transition: all 0.3s ease-in;
    }

    .stock-card-bottom-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        position: relative;
        z-index: 1;

        button {
            padding: 10px 30px;
            border: none;
            background: #00B5EC;
            border-radius: 4.35503px;
            font-family: Stem-Medium;
            font-size: 1rem;
            line-height: 1.125rem;
            color: var(--white-color);
            cursor: pointer;
        }

        p {
            font-family: Stem-Regular;
            font-size: 0.875rem;
            line-height: 1rem;
            color: rgba(160, 160, 160, 0.8);
            transition: all 0.3s ease-in;
        }
    }
    &:hover{
            h1 { color: var(--white-color);}
            p { color: var(--white-color);}
            .stock-card-bottom-item{
                p{
                    color: var(--white-color);
                }
            }
             .stock-card-bg{ top:0%;}


     

    }

}

.stock-card-text-content {
    display: flex;
    flex-direction: column; 
    min-height: 100px;
    position: relative;
    z-index: 1;
}

.desktop-slick {
    display: block;
}

.phone-slick {
    display: none;
}

@media screen and(max-width:1700px) {
    .stock-one-items {
        transform: translateY(-140px);

    }
}

@media screen and(max-width:1530px) {
    .stock-one-items {
        transform: translateY(-170px);

    }
}

@media screen and(max-width:1300px) {
    
    .stock-one-items {
        transform: translateY(-120px);
    }

    .stock-item-left {
        padding-right: 30px;

        .stock-left-title {
            h1 {
                font-family: Stem-Medium;
                font-size: 28px;
                line-height: 30px;
            }

            p {
                font-family: Stem-Medium;
                font-size: 1rem;
                line-height: 1.125rem;
                color: var(--white-black);
                margin-top: 20px;
            }
        }
    }
}

@media screen and(max-width:1100px) {
    .stock-top-view {
        padding: 180px 20px;
    }

    .stock {
        padding: 0px 20px;
    }
}

@media screen and(max-width:1000px) {
     .stock-top-view {
        padding: 180px 20px;
    }

    .stock-top-bg {
        display: none;
    }

    .stock-item-left {
        width: 100%;
        padding: 0px 0px 30px 0px;
        flex: none;

        .stock-left-title {
            h1 {
                text-align: center;
            }

            p {
                text-align: center;
            }
        }
    }

    .stock-item-right {
        width: 100%;
        max-width: 100%;
        padding: 0px 0px 30px 0px;

        .stock-card-content {
            padding: 20px 20px;
        }
    }

    .stock-one-items {
        flex-wrap: wrap;
        transform: translateY(-100px);

    }

    .desktop-slick {
        display: none;
    }

    .phone-slick {
        display: block;
    }

    .stock-card {
        padding: 20px;
        background: var(--white-bg);
        box-shadow: 0px 6.53254px 17.4201px -8.71006px rgba(0, 72, 153, 0.12), 0px 9.79882px 30.4852px rgba(0, 72, 153, 0.05), 0px 13.0651px 52.2604px 17.4201px rgba(0, 72, 153, 0.03);
        border-radius: 5px;
        max-width: 370px;
        width: 100%;

        h1 {
            font-size: 1rem;
            line-height: 19px
        }

        p {
            font-size: 0.75rem;
            line-height: 0.875rem;
        }

        .stock-card-bottom-item {
            display: flex;
            align-items: center;
            justify-content: space-between;

            button {
                padding: 10px 20px;
                font-size: 0.875rem;
                line-height: 1rem;
            }

            p {
                font-size: 0.75rem;
                line-height: 0.875rem;
            }
        }

    }

}

@media screen and(max-width:768px) {

    .stock-top-view {
        padding: 60px 20px;
        height: 75px;
        padding: 0;
    }

    .stock-top-bg {
        display: none;
    }

    .stock {
        padding: 0;
    }

    .stock-one-items {
        flex-wrap: wrap;
        transform: translateY(0px);
        padding: 0px 0px;

    }

    .stock-item-left {
        width: 100%;
        padding: 0px 0px 30px 0px; 

        .stock-left-title {
            padding: 40px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 160px;

            h1 {
                font-size: 24px;
                line-height: 28px;
                color: var(--white-color); 
            }

            p {
                font-size: 1.125rem;
                line-height: 24px;
                color: rgba(255, 255, 255, 0.8);
                display: none;
            }

            .stock-left-title-bg {
                display: block;
            }
        }

        .stock-image-content {
            display: none;
        }

        .stock-image-content {
            margin-top: 0px;
            border-radius: 0px;
            overflow: hidden;

            img {
                width: 100%;
            }
        }
        .stock-date{ padding:20px 20px 0px 20px; display: block; }
    }

    .stock-item-right {
        width: 100%;
        padding: 0px 0px 30px 0px;

        .stock-item-right-title {
            padding: 0px 20px;
        }

        .stock-card-content {
            padding: 20px 20px;
        }
    }

    .stock-text {
        padding: 20px;
    }

    .stock-card-text-content {
        min-height: 80px;
    }

}

@media screen and(max-width:400px) {
    .stock-card {
        padding: 16px 20px;
        max-width: 320px;
    }
}

@media screen and(max-width:350px) {
    .stock-card {
        padding: 16px 20px;
        max-width: 300px;
    }
}
</style>
